<template>
	<S2SForm :title="`Bag ${'#' + bagSerial} Events`">
		<v-data-table :headers="bagHeaders" :items="bagEvents" class="elevation-1" item-key="id" :loading="eventsStatus.loading" :options.sync="pagination">
			<template v-slot:item.transactiondate="{ item }">
				<span class="text-no-wrap">{{ formatDateTime(item.transactiondate) }}</span>
			</template>
			<template v-slot:item.amount="{ item }">
				<span class="text-no-wrap">{{ formatCurrency(item.amount) }}</span>
			</template>
			<template v-slot:item.createdate="{ item }">
				<span class="text-no-wrap">{{ formatDateTime(item.createdate) }}</span>
			</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import Vue from "vue";

import { Status } from "@/store/utils";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "SearchClient",

	props: {
		bagid: [Number, String]
	},

	data: function() {
		return {
			pagination: {
				descending: true,
				itemsPerPage: 10,
				sort: "transactiondate"
			},
			bagHeaders: [
				{ text: "Transaction Id", value: "transactionid" },
				{ text: "Beneficiary Code", value: "beneficiarycode" },
				{ text: "Cit Code", value: "citcode" },
				{ text: "Device Serial", value: "deviceserial" },
				{ text: "Transaction Date", value: "transactiondate", type: "dateTime" },
				{ text: "Transaction Type", value: "transactiontype" },
				{ text: "Amount", value: "amount", type: "currency" },
				{ text: "Status", value: "status" },
				{ text: "Collection unit", value: "collectionunit" },
				{ text: "Bag Number Id", value: "bagnumberid" },
				{ text: "Payment Id", value: "paymentid" },
				{ text: "Create Date", value: "createdate", type: "dateTime" }
			]
		};
	},

	computed: {
		eventsStatus: function() {
			return this.$store.state.safes.eventsStatus;
		},
		bagEvents: function() {
			return this.$store.state.safes.bagEvents;
		},
		bagSerial: function() {
			return this.bagEvents.length > 0 ? this.bagEvents[0].collectionunit : "";
		}
	},

	watch: {
		bagid: {
			immediate: true,
			handler(id) {
				this.$store.dispatch("safes/fetchEventsByBagId", id);
			}
		}
	},

	methods: {
		formatDateTime(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		},
		formatCurrency(val) {
			return Utils.formatText(val, Utils.TextType.CURRENCY);
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}
</style>
